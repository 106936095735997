import React, { FunctionComponent } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import AppShell from './components/app-shell';
import LoadingPage from './components/loading';
import Login from './components/login';
import { useAuthentication } from './contexts/authentication';
import { useUser } from './contexts/user';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute
        path={['/store-details', '/country-summary', '/profile', '/help']}
        component={AppShell}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>
);

interface PrivateRouteProps {
  component: FunctionComponent;
  path: string | string[];
}

export const PrivateRoute = ({ component, path }: PrivateRouteProps) => {
  const { isAuthenticated, loading } = useAuthentication();
  const { loading: userLoading } = useUser();
  if (loading || userLoading) return <LoadingPage />;
  return isAuthenticated ? <Route path={path} component={component} /> : <Redirect to="/" />;
};

export default Router;
