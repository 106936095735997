import { AppPermission } from '../types';

export const ScreenRoutes: { [key: string]: string } = {
  STORES: '/country-summary',
  NODES: '/store-details',
};

export const RoutePermissions: { [key: string]: AppPermission } = {
  '/country-summary': 'STORES',
  '/store-details': 'NODES',
};
