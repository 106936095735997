import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from '../../../router';

const Details = lazy(() => import('../../../pages/store-details'));
const Summary = lazy(() => import('../../../pages/country-summary'));
const Profile = lazy(() => import('../../../pages/profile'));
const Help = lazy(() => import('../../help'));

const ContentRouter = () => (
  <Switch>
    <PrivateRoute path="/store-details" component={Details} />
    <PrivateRoute path="/country-summary" component={Summary} />
    <PrivateRoute path="/profile" component={Profile} />
    <PrivateRoute path="/help" component={Help} />
  </Switch>
);

export default ContentRouter;
