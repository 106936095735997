import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Hidden,
  Drawer,
  useTheme,
  useMediaQuery,
  ListSubheader,
} from '@material-ui/core';
import SummaryIcons from '@material-ui/icons/Dashboard';
import DetailIcon from '@material-ui/icons/Description';
import { useHistory, useLocation } from 'react-router-dom';
import AppsIcon from '@material-ui/icons/Apps';
import Footer from './footer';
import { useTranslation } from 'react-i18next';
import { useReport } from '../../../contexts/report';
import { useUser } from '../../../contexts/user';
// import { useUser } from '../../../contexts/user';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    root: { zIndex: theme.zIndex.drawer },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

interface Sidebar {
  isOpen: boolean;
  toggle: () => void;
}

const Sidebar = ({ isOpen, toggle }: Sidebar) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const shouldRenderSidebar = !['/help', '/profile'].includes(location.pathname);
  const { t } = useTranslation();
  const { resetData } = useReport();
  const { permissions } = useUser();

  const handleLinkClick = (path: string) => {
    if (isMobile) toggle();
    resetData();
    history.push(path);
  };

  const drawerList = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListSubheader>{t('layout.drawer.reports')}</ListSubheader>
        {permissions.includes('STORES') && (
          <ListItem
            button
            onClick={() => handleLinkClick('/country-summary')}
            selected={location.pathname === '/country-summary'}
          >
            <ListItemIcon>
              <SummaryIcons />
            </ListItemIcon>
            <ListItemText primary={t('layout.drawer.countrySummary')} />
          </ListItem>
        )}
        {permissions.includes('NODES') && (
          <ListItem
            button
            onClick={() => handleLinkClick('/store-details')}
            selected={location.pathname === '/store-details'}
          >
            <ListItemIcon>
              <DetailIcon />
            </ListItemIcon>
            <ListItemText primary={t('layout.drawer.storeDetails')} />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => (window.location.href = process.env.REACT_APP_LAUNCHER_URL || '')}
        >
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Flex Tiles" />
        </ListItem>
      </List>
      <Footer />
    </div>
  );

  if (!shouldRenderSidebar) return null;

  return (
    <nav className={classes.drawer} aria-label="sidebar">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isOpen}
          onClose={toggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{ zIndex: theme.zIndex.drawer }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerList}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawerList}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default Sidebar;
