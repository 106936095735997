/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useAlert } from './alert';
import { useTranslation } from 'react-i18next';
import { IdTokenClaims } from '@azure/msal-common';

const config: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/c56a089c-7ef0-4fcb-b7eb-3858330142ed',
    clientId: 'e22e5681-c704-4072-a977-ec71ad1194c1',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

const APP_TOKEN = 'npverify-app-token';
const scopes = ['User.Read', 'openid', 'profile', 'offline_access'];

interface AuthenticationContext {
  login: () => void;
  logout: () => void;
  getToken: () => Promise<{ accessToken: string; idToken: string }>;
  account: AccountInfo | null;
  loading: boolean;
  isAuthenticated: boolean;
}

const initialState: AuthenticationContext = {
  logout: () => {},
  login: () => {},
  getToken: () => new Promise(() => {}),
  account: null,
  loading: false,
  isAuthenticated: true,
};

const AuthenticationContext = createContext(initialState);
export const authClient = new PublicClientApplication(config);

export const AuthenticationProvider = ({ children }: { children: ReactNode }) => {
  const [account, setAccount] = useState<AccountInfo | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showAlert } = useAlert();
  const { t } = useTranslation();

  const login = () => {
    setLoading(true);
    authClient.loginRedirect();
  };

  useEffect(() => {
    authClient
      .handleRedirectPromise()
      .then(response => {
        setLoading(false);
        if (response) {
          setAccount(response.account);
          setIsAuthenticated(true);
        }
      })
      .catch(error => console.log(error));
    const accounts = authClient.getAllAccounts();
    switch (accounts.length) {
      case 0:
        setIsAuthenticated(false);
        if (window.location.hash !== '') {
          setLoading(true);
          authClient
            .ssoSilent({ scopes, loginHint: window.location.hash.split('#')[1] })
            .then(() => {
              setAccount(authClient.getAllAccounts()[0]);
              setIsAuthenticated(true);
            })
            .catch(() => {
              showAlert(t('errors.auth.noSSO.title'), t('errors.auth.noSSO.description'));
            });
        }
        break;
      case 1:
        setAccount(accounts[0]);
        setIsAuthenticated(true);
        break;
      default:
        console.log(accounts);
        break;
    }
    //eslint-disable-next-line
  }, []);

  function forceRefresh(currentTime: number, exp?: number) {
    if (!exp) return true;
    if (exp - currentTime < 600) return true;
    else return false;
  }

  const setAppToken = (token: AuthenticationResult | null) => {
    localStorage.setItem(APP_TOKEN, JSON.stringify(token));
  };

  const getAppToken = (): AuthenticationResult | null =>
    JSON.parse(localStorage.getItem(APP_TOKEN) || 'null');

  const getToken = async (): Promise<{ accessToken: string; idToken: string }> => {
    try {
      const currentTime = new Date().getTime() / 1000; // seconds
      const appToken = getAppToken();

      if (appToken && !forceRefresh(currentTime, (appToken.idTokenClaims as IdTokenClaims).exp)) {
        return { accessToken: appToken.accessToken, idToken: appToken.idToken };
      } else {
        const result = await authClient.acquireTokenSilent({
          account: account!,
          scopes,
          forceRefresh: true,
        });
        setAppToken(result);
        return { accessToken: result.accessToken, idToken: result.idToken };
      }
    } catch (error) {
      const result = await authClient.acquireTokenPopup({
        account: account!,
        scopes,
      });
      setAppToken(result);
      return { accessToken: result.accessToken, idToken: result.idToken };
    }
  };

  const logout = () => {
    localStorage.removeItem('userPermissions');
    sessionStorage.removeItem('master-data');
    authClient.logout();
  };

  return (
    <AuthenticationContext.Provider
      value={{ logout, login, getToken, loading, isAuthenticated, account }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
