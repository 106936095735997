import Axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import React, { createContext, ReactNode, useContext } from 'react';
import {
  LabelLanguage,
  AppPermission,
  Store,
  TransformedFilters,
  NodesResponse,
  SummaryReport,
  FilterLabels,
} from '../types';
import { useAuthentication } from './authentication';

interface ApiContext {
  fetchMasterData: () => AxiosPromise<Store[]>;
  fetchStores: (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage
  ) => AxiosPromise<SummaryReport[]>;
  fetchNodes: (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage
  ) => AxiosPromise<NodesResponse>;
  fetchReport: (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<void>;
  fetchFile: (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => AxiosPromise<void>;
  fetchPermissions: () => AxiosPromise<AppPermission[]>;
}

const initialState: ApiContext = {
  fetchMasterData: () => new Promise(() => {}),
  fetchStores: (_queryParameters: TransformedFilters) => new Promise(() => {}),
  fetchNodes: (_queryParameters: TransformedFilters) => new Promise(() => {}),
  fetchReport: (_queryParameters: TransformedFilters) => new Promise(() => {}),
  fetchFile: (_queryParameters: TransformedFilters) => new Promise(() => {}),
  fetchPermissions: () => new Promise(() => {}),
};

const ApiContext = createContext(initialState);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
  const { getToken } = useAuthentication();
  const flexApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/rs`) ||
      'https://api-flex-dev.arcosdorados.net/rs',
  });
  const npverifyApi = Axios.create({
    baseURL:
      (process.env.REACT_APP_API_URL && `${process.env.REACT_APP_API_URL}/npverify`) ||
      'https://api-flex-dev.arcosdorados.net/npverify',
  });

  flexApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'npverify',
      };
      return value;
    } else throw Error('token-error');
  });

  npverifyApi.interceptors.request.use(async (value: AxiosRequestConfig) => {
    const { accessToken, idToken } = await getToken();
    if (idToken && accessToken) {
      value.headers = {
        'access-token': accessToken,
        Authorization: `Bearer ${idToken}`,
        appId: 'npverify',
      };
      return value;
    } else throw Error('token-error');
  });

  const fetchStores = async (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    return npverifyApi.get('/stores', {
      params: { ...queryParameters, export: exportRequest, labels, filterLabels },
    });
  };

  const fetchNodes = async (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    const filters: TransformedFilters = {
      division: queryParameters.division,
      country: queryParameters.country,
      store_acronym: queryParameters.store_acronym,
      idFile: queryParameters.idFile,
      nodeStatus: queryParameters.nodeStatus,
    };
    return npverifyApi.get('/nodes', {
      params: { ...filters, export: exportRequest, labels, filterLabels },
    });
  };

  const fetchReport = async (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    return npverifyApi.get('/report', {
      params: { ...queryParameters, export: exportRequest, labels, filterLabels },
    });
  };

  const fetchFile = async (
    queryParameters: TransformedFilters,
    exportRequest?: boolean,
    labels?: LabelLanguage,
    filterLabels?: FilterLabels
  ) => {
    const filters: TransformedFilters = {
      division: queryParameters.division,
      country: queryParameters.country,
      store_acronym: queryParameters.store_acronym,
      idFile: queryParameters.idFile,
      nodeStatus: queryParameters.nodeStatus,
    };
    return npverifyApi.get('/file', {
      params: { ...filters, export: exportRequest, labels, filterLabels },
    });
  };

  const fetchMasterData = () => flexApi.get('/op-structures');

  const fetchPermissions = () => flexApi.get('/permissions');

  return (
    <ApiContext.Provider
      value={{
        fetchNodes,
        fetchStores,
        fetchReport,
        fetchFile,
        fetchMasterData,
        fetchPermissions,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
