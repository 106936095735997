/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import { useAlert } from './alert';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from './authentication';
import { AppPermission } from '../types';
import { useApi } from './api';
import { ScreenRoutes } from '../utils/constants';

interface UserContext {
  permissions: AppPermission[];
  getUserPermissions: () => Promise<void>;
  getAllowedScreens: () => string[];
  loading: boolean;
}

const initialState: UserContext = {
  permissions: [],
  getUserPermissions: () => new Promise<void>(() => {}),
  getAllowedScreens: () => [],
  loading: false,
};

const UserContext = createContext(initialState);

interface Provider {
  children: ReactNode;
}

export const UserProvider = ({ children }: Provider) => {
  const [permissions, setPermissions] = useState<AppPermission[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { showAlert, showError } = useAlert();
  const { isAuthenticated } = useAuthentication();
  const { fetchPermissions } = useApi();

  const getUserPermissions = async () => {
    try {
      setLoading(true);
      const data = (await fetchPermissions()).data;
      setPermissions(data);
      if (data.length === 0) {
        showAlert(t('errors.permissions.title'), t('errors.permissions.no_permission'), [
          {
            label: t('errors.permissions.backToTiles'),
            handleClick: () =>
              (window.location.href =
                process.env.REACT_APP_LAUNCHER_URL || 'https://flex-dev.arcosdorados.net'),
          },
        ]);
      }
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const getAllowedScreens = () => {
    let allowedScreens: string[] = [];
    if (permissions.includes('STORES')) allowedScreens.push(ScreenRoutes['STORES']);
    if (permissions.includes('NODES')) allowedScreens.push(ScreenRoutes['NODES']);
    return allowedScreens;
  };

  useEffect(() => {
    const init = async () => {
      await getUserPermissions();
    };
    if (isAuthenticated) {
      init();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        permissions,
        loading,
        getUserPermissions,
        getAllowedScreens,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
