/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LabelLanguage, DetailReport, SummaryReport } from '../types';
import { useAlert } from './alert';
import { useApi } from './api';
import { useFilters } from './filters';

import en from '../static/locales/en.json';
import es from '../static/locales/es.json';
import fr from '../static/locales/fr.json';
import pt from '../static/locales/pt.json';
interface ReportContext {
  detailReport: DetailReport[];
  summaryReport: SummaryReport[];
  loading: boolean;
  autoReport: boolean;
  requestExport: () => Promise<void>;
  getDetailReport: () => Promise<void>;
  getSummaryReport: () => Promise<void>;
  requestDetails: () => Promise<void>;
  handleAutoReport: () => void;
  resetData: () => void;
}

const initialState: ReportContext = {
  detailReport: [],
  summaryReport: [],
  loading: false,
  autoReport: false,
  requestExport: () => new Promise(() => {}),
  getDetailReport: () => new Promise(() => {}),
  getSummaryReport: () => new Promise(() => {}),
  requestDetails: () => new Promise(() => {}),
  handleAutoReport: () => {},
  resetData: () => {},
};

const ReportContext = createContext(initialState);

export const ReportProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const [detailReport, setDetailReport] = useState<DetailReport[]>([]);
  const [summaryReport, setSummaryReport] = useState<SummaryReport[]>([]);
  const [loading, setLoading] = useState(false);
  const [autoReport, setAutoReport] = useState(false);
  const { getTransformedFilters, setIdFile } = useFilters();
  const { fetchNodes, fetchStores, fetchReport, fetchFile } = useApi();
  const { filters, resetFilters, getFilterLabels } = useFilters();
  const { showError, showAlert } = useAlert();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setSummaryReport([]);
    setDetailReport([]);
    // eslint-disable-next-line
  }, [
    filters.division,
    filters.countries,
    filters.division,
    filters.storeStatus,
    filters.nodeStatus,
    filters.managements,
    filters.regionalManagements,
    filters.regions,
    filters.supervisions,
  ]);

  const getSummaryReport = async () => {
    try {
      setLoading(true);
      const response = await fetchStores(getTransformedFilters());
      setSummaryReport(response.data);
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
      autoReport && setAutoReport(false);
    }
  };

  const getDetailReport = async () => {
    try {
      setLoading(true);
      const response = await fetchNodes(getTransformedFilters());
      let formatterData: DetailReport[] = [];
      const data = response.data;
      if (data && data.nodes) {
        data.nodes.forEach(e => {
          const missing = e.summary.filter(m => m.type === 'Missing');
          const differences = e.summary.filter(m => m.type === 'Differences');
          formatterData.push({
            ...e,
            countryAcronym: data.countryAcronym,
            storeAcronym: data.storeAcronym,
            idFile: data.idFile,
            missing: missing.map(e => `${e.directory}: ${e.quantity}`).join(),
            differences: differences.map(e => `${e.directory}: ${e.quantity}`).join(),
          });
        });
      }
      setIdFile('');
      setDetailReport(formatterData);
    } catch (error) {
      console.log(error);
      showError(error);
    } finally {
      setLoading(false);
      autoReport && setAutoReport(false);
    }
  };

  const handleAutoReport = () => {
    setAutoReport(true);
  };

  const requestExport = async () => {
    try {
      setLoading(true);
      let labels: LabelLanguage = {};
      switch (i18n.language) {
        case 'en':
        case 'en-US':
          labels = { ...en.table.columns };
          break;
        case 'es':
        case 'es-US':
          labels = { ...es.table.columns };
          break;
        case 'fr':
        case 'fr-US':
          labels = { ...fr.table.columns };
          break;
        case 'pt':
        case 'pt-US':
          labels = { ...pt.table.columns };
          break;
        default:
          break;
      }
      showAlert(t('dialogs.exportRequested.title'), t('dialogs.exportRequested.body'));
      await fetchReport(getTransformedFilters(), true, labels, getFilterLabels(true));
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const requestDetails = async () => {
    setLoading(true);
    try {
      let labels: LabelLanguage = {};
      switch (i18n.language) {
        case 'en':
          labels = { ...en.table.columns };
          break;
        case 'es':
          labels = { ...es.table.columns };
          break;
        case 'fr':
          labels = { ...fr.table.columns };
          break;
        case 'pt':
          labels = { ...pt.table.columns };
          break;
        default:
          break;
      }
      showAlert(t('dialogs.exportRequested.title'), t('dialogs.exportRequested.bodyDetails'));
      await fetchFile(getTransformedFilters(), true, labels, getFilterLabels());
    } catch (error) {
      showError(error);
    } finally {
      setLoading(false);
    }
  };

  const resetData = () => {
    setSummaryReport([]);
    setDetailReport([]);
    resetFilters();
  };

  return (
    <ReportContext.Provider
      value={{
        detailReport,
        summaryReport,
        loading,
        autoReport,
        requestExport,
        requestDetails,
        getDetailReport,
        getSummaryReport,
        handleAutoReport,
        resetData,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};

export const useReport = (): ReportContext => useContext(ReportContext);
